<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
    <!-- EOC -->
    <!-- BOC:[tab] -->
    <div class="d-flex justify-end mb-3">
      <v-btn :exact="true" text color="primary" :to="{name:'PageMainEventScoreboard',params:{code:$route.params.eventCode},query:{ pw: $route.query.pw}}">{{$t("route.PageMainEventScoreboard")}}</v-btn>
      <v-btn :exact="true" text color="primary" :to="{name:'PageMainEventGuide',params:{code:$route.params.eventCode},query:{ pw: $route.query.pw}}">{{$t("route.PageMainEventGuide")}}</v-btn>
      <v-btn :exact="true" v-if="auth && auth.Account && (auth.Account.role == 'moderator' || auth.Account.role == 'admin')" text color="primary" :to="{name:'PageMainEventAnalysis',params:{code:$route.params.eventCode},query:{ pw: $route.query.pw}}">{{$t("route.PageMainEventAnalysis")}}</v-btn>
    </div>
    <!-- EOC -->
    <!-- BOC:[content] -->
    <div v-if="api.loading && !event" class="text-center pa-5">
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
    </div>
    <div v-else>
 <v-card v-if="Auth || !password || auth.token">
      <v-card-title>
        <span v-if="$_getLocale() == 'zh'">活动指南（监护人用）</span>
        <span v-else>Garis Panduan Untuk Penjaga</span>
      </v-card-title>
      <v-card-subtitle>{{event.name}}
      </v-card-subtitle>
      <v-card-text class="black--text">
        <v-img
          src="/images/LoginStudent.png"
          contain
          height="100px"
        ></v-img>
        <ol class="list" v-if="$_getLocale() == 'zh'">
          <li>学生可以如何参与这活动呢？</li>
          <ul>
            <li>这活动将使用Hamochi网站进行。</li>
            <li>这活动将在{{$moment(event.timestampStart).locale('zh_cn').format("LLLL")}}开始。</li>
            <li>并于{{$moment(event.timestampEnd).locale('zh_cn').format("LLLL")}}结束。</li>
            <li>在活动期间的任何时候，学生可以使用个人手机或电脑通过以下活动网址参与活动。</li>
            <li>活动网址：<a :href="urlGame" target="_blank">{{urlGame}}</a></li>
            <li>{{school.name}}的学校代码是：{{school.code}}</li>
          </ul>
          <li>这活动的主要目的是什么呢？</li>
          <ul>
            <li>我们鼓励学生们复习并运用已经学会的知识。</li>
            <li>学生们在活动期间依然可以查阅课本等寻求解答方法。</li>
            <li>通过比赛和游戏，我们鼓励他们学会自主寻找解答方法，提升他们对学习的兴趣。</li>
          </ul>
          <li>为什么使用游戏进行这活动呢？</li>
          <ul>
            <li>许多研究报告指出比起在高压的环境里学习，通过玩乐学习是最有效的。</li>
            <li>在日常生活里，小孩子往往缺乏使用数学知识的机会。</li>
            <li>通过游戏，我们可以模拟使用数学知识的需要来鼓励学生运用已经学过的知识。</li>
          </ul>
          <li>活动前需要怎么样的准备吗？</li>
          <ul>
            <li>我们鼓励学生们事前预习已经学会了的知识。</li>
            <li>监护人可以在「记分牌」里的「课题」页面查阅出题范围。</li>
          </ul>
          <li>监护人须扮演怎么样的角色呢？</li>
          <ul>
            <li>我们鼓励监护人与学生共享这活动。</li>
            <li>监护人的角色十分重要。小孩子是通过模仿身边的大人学习的。</li>
            <li>监护人可以在活动开始时确保学生可以参与活动。</li>
            <li>监护人可以在活动期间鼓励学生解答所有的课题。</li>
            <li>如果学生遇到什么学术上的困难，监护人可以指导学生解答。</li>
            <li>但是我们不鼓励监护人拿走手机或电脑直接干预解答过程。</li>
          </ul>
          <li>比赛如何计分呢？</li>
          <ul>
            <li>比赛会有{{event.Checkpoint.length}}个课题。</li>
            <li>每个课题学生必须解答10道数学题。</li>
            <li>每答对一题可获得80分。</li>
            <li>每回答一题后，学生可以玩一场小游戏。</li>
            <li>在小游戏里学生最多可以获得20分。</li>
            <li>系统会自动统计各课题的最高得分。</li>
            <li>学生如果对自己的表现不满意可以再次回答同个课题来取得更高的得分。</li>
          </ul>
          <li>学生可以重复解答同样的课题吗？</li>
          <ul>
            <li>可以。我们十分鼓励学生多做复习。</li>
            <li>别担心。为防学生盲目背诵答案，每次学生开始回答时，系统会自动制作不同的问题。</li>
          </ul>
          <li>为什么每次回答时数学题都会不同呢？</li>
          <ul>
            <li>Hamochi系统会每次自动制作不同的数学题。</li>
            <li>有两个主要原因。</li>
            <li>第一，为了防止学生盲目的背诵答案。</li>
            <li>第二，为了防止学生抄袭。</li>
            <li>我们鼓励学生学习并了解正确的解答法。</li>
          </ul>
          <li>监护人可以查看学生的排名吗？</li>
          <ul>
            <li>可以。监护人可以随时游览「记分牌」来查看学生的排名和进度。</li>
            <li>记分牌：<router-link :to="{name:'PageMainEventScoreboard',params:{code:$route.params.eventCode},query:{ pw: $route.query.pw}}">点击查阅</router-link></li>
          </ul>
          <li>「记分牌」的【弱项】是什么意思？</li>
          <ul>
            <li>比赛每个课题可获0至3颗星🌟。得分越高，星星的数量就🌟越多。</li>
            <li>如果学生每个课题都拿3颗星🌟（满分），记分牌的【弱项】里就会显示【没有弱项】。</li>
            <li>如果学生在一些课题拿到低于3颗星🌟以下，记分牌的【弱项】里就会显示表现最差的课题。</li>
            <li>监护人可以通过记分牌的【弱项】来判断学生需要加强学习的部分。</li>
          </ul>
          <li>如果个人手机或电脑无法开启Hamochi怎么办？</li>
          <ul>
            <li>先查看您的软件可否被支撑</li>
            <ul>
              <li>✅ Windows (Edge 16+, Chrome 56+, Firefox 51+)</li>
              <li>✅ Android tablet</li>
              <li>✅ Android phone</li>
              <li>⚠️ Macbook （无法使用Safari，需下载并使用Chrome）</li>
              <li>⚠️ iPad （无法使用Safari，需下载并使用Chrome）</li>
              <li>⚠️ iPhone （无法使用Safari，需下载并使用Chrome）</li>
            </ul>
            <li>如果使用可支撑的软件也无法开启Hamochi，可以尝试使用以下的简易版。</li>
            <li>简易版：<a target="_blank" :href="urlLite">{{urlLite}}</a></li>
          </ul>
          <li>如果数学题里出现▯▯▯等的不明符号怎么办？</li>
          <ul>
            <li>有时太久没更新个人手机或电脑会遇到类似的状况。</li>
            <li>建议使用相关手机或电脑来阅读以下图案。如果无法阅读任何一个图案，须更新操作系统（OS）。</li>
            <ul>
              <li>🥮 月饼 (最后更新2018年左右)</li>
              <li>🥧 馅饼 (最后更新2017年左右)</li>
              <li>🥕 萝卜 (最后更新2016年左右)</li>
              <li>🦁 狮子 (最后更新2015年左右)</li>
            </ul>
            <li>如果更新操作系统和尝试简易版后都无法解决这问题，请点击这网页最下方的「线上客服」按键来联络我们的技术人员。</li>
          </ul>
          <li>如果使用上遇到困难可以联络谁呢？</li>
          <ul>
            <li>如果使用上遇到任何困难，请点击这网页最下方的「线上客服」按键来联络我们的技术人员。</li>
          </ul>
        </ol>
        <pre v-else>
Pertandingan ini akan diadakan melalui Hamochi laman web.

1. Senarai peranti yang menyokong Hamochi
✅ Windows (Edge 16+, Chrome 56+, Firefox 51+)
✅ Android tablet
✅ Android phone
⚠️ Macbook (Perlu menggunakan Chrome)
⚠️ iPad (Perlu menggunakan Chrome)
⚠️ iPhone (Perlu menggunakan Chrome)

2. Jika kelajuan internet terlalu perlahan ataupun peranti kamu tidak menyokong Hamochi versi penuh, boleh menggunakan versi lite <a target="_blank" :href="urlLite">{{urlLite}}</a>

3. Adakah murid boleh mengulangi latihan dalam pertandingan ini?
- Boleh. Soalannya akan berbeza jika murid mengulangi latihan. Murid-murid boleh bermain dengan berulang kali pada bila-bila masa saja bagi mencapai markah yang tertinggi sepanjang tempoh pertandingan ini. 

4. Bagaimana markah dikira?
- Setiap topik mempunyai sepuluh soalan.
- Setiap soalan bernilai 80 markah.
- Setiap ikan bernilai 10~20 markah.

5. Bagaimana jumlah markah pertandingan dikira?
- Sistem akan mengira markah yang tertinggi bagi setiap topik yang telah dijawab. Murid-murid boleh mengulangi latihan dalam pertandingan ini bagi mencapai markah yang tertinggi.

6. Apakah makna "Kelemahan" dalam "Papan Markah"?
- Setiap topik mempunyai 0~3 bintang 🌟. Bilangan bintang akan semakin BANYAK apabila markah semakin tinggi. 
- Sekiranya murid-murid mendapat markah penuh dengan 3 bintang 🌟 untuk setiap topik yang mereka jawab, sistem akan menunjukkan "Tiada kelemahan" pada "Kelemahan" dalam "Papan Markah".
- Sekiranya murid-murid mendapat markah kurang daripada 3 bintang 🌟, sistem akan menunjukkan topik yang mereka dapat markah terendah pada "Kelemahan" dalam "Papan Markah".

7. Kenapa soalan menunjukkan kotak-kotak pelik seperti ▯▯▯?
- kerana peranti kamu sudah lama tidak pernah "update" ia punya OS.
- Kamu boleh menyemak tahun OS kamu dengan membaca simbol berikut melalui peranti kamu. 
- 🥮 Kek Bulan (Tahun 2018) 
- 🥧 Pai (Tahun 2017) 
- 🥕 Lobak (Tahun 2016) 
- 🦁 Singa (Tahun 2015)
- Hamochi menggunakan gambar sebelum 2018. Jika peranti kamu tak dapat membaca simbol atas, cuba menggunakan peranti lain untuk menyertai pertandingan ini.
        </pre>
      </v-card-text>
      <v-card-action>
        <div class="justify-center d-flex pb-5">
          <v-btn
            x-large
            color="#25D366"
            class="mb-3 white--text"
            href="https://api.whatsapp.com/send?phone=60123989864"
            target="_blank"
          >
            <v-img width="40" src="/images/Contact/Whatsapp.svg"></v-img>
            {{ $t("view.PageMainHome.slide_contact_cta") }}
          </v-btn>
        </div>
      </v-card-action>
    </v-card>
    <!-- EOC -->
      <div v-else>
      <v-row align="center" justify="center">
        <v-col>
          <v-card
            v-if="!$route.query.pw"
            class="mx-auto text-center"
            max-width="500"
          >
            <v-card-title>
              <div class="text-center mx-auto">
                <v-icon class="mr-2" color="black">mdi-alert</v-icon>
                <span>This contest is password protected.</span>
              </div>
            </v-card-title>
            <v-card-text>You may get the password from the host.</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <Password
                class="mb-3"
                buttonText="ENTER PASSWORD"
                oldPassword="true"
                :code="$route.params.eventCode"
              />
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
          <v-card v-else class="mx-auto text-center" max-width="500">
            <v-card-title>
              <div class="text-center mx-auto">
                <v-icon class="mr-2" color="black">mdi-alert</v-icon>
                <span>Access denied.</span>
              </div>
            </v-card-title>
            <v-card-text>Please enter correct password.</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <Password
                class="mb-3"
                buttonText="ENTER PASSWORD"
                oldPassword="true"
                pageName="PageMainEventGuide"
                :code="$route.params.eventCode"
              />
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>
    </div>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import Password from "@/components/Event/Password/Dialog";
export default {
  components: {
     Password,
  },
  computed: mapState({
    auth: state => state.auth.data,
  }),
  data: () => ({
    Auth:null,
    password:null,
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    event:null,
    school:null,
    urlGame: process.env.VUE_APP_URL_GAME,
    urlLite: process.env.VUE_APP_URL_LITE,
  }),
  created() {
    //BOC:[breadcrumbs]
    this.breadcrumbs.push({
      text: this.$t("route.PageMainHome"),
      to: { name: "PageMainHome" },
      exact: true,
    });
    this.breadcrumbs.push({
      text: this.$t("route.PageMainEvent"),
      to: { name: "PageMainEvent" },
      exact: true,
    });
    //
    if(this.$route.query.pw){
      this.breadcrumbs.push({
        text:this.$route.params.eventCode,
        to: {
          name: "PageMainEventScoreboard",
          params: {
            code: this.$route.params.eventCode,
          },
          query: {
            pw: this.$route.query.pw,
          },
        },
        exact:true,
      })
    }
    else{
      this.breadcrumbs.push({
        text: this.$route.params.eventCode,
        to: {
          name: "PageMainEventScoreboard",
          params: {
            code: this.$route.params.eventCode,
          },
        },
        exact: true,
      });
    }
    //
    if (this.$route.query.pw) {
      this.breadcrumbs.push({
        text: this.$t("route.PageMainEventGuide"),
        to: {
          name: "PageMainEventGuide",
          params: {
            code: this.$route.params.eventCode,
          },
          query: {
            pw: this.$route.query.pw,
          },
        },
        exact: false,
      });
    } 
    else {
      this.breadcrumbs.push({
        text: this.$t("route.PageMainEventGuide"),
        to: {
          name: "PageMainEventGuide",
          params: {
            code: this.$route.params.eventCode,
          },
        },
        exact: false,
      });
    }
    //EOC
    //BOC:[api]
    this.api.url = `${this.$api.servers.classroom}/v1/${this.$_getLocale()}/event/${this.$route.params.eventCode}?pw=${this.$route.query.pw}`;
    this.api.method = "get";
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      const { School,Auth,password, ...Event } = resp
      this.event = Event
      this.school = School
      this.Auth = Auth
      this.password = password
      this.urlGame = `${this.urlGame}?lang=${this.$_getLocale()}&code=${School.code}`
      this.urlLite = `${this.urlLite}${this.$_getLocale()}/home?code=${School.code}`
    };
    //EOC
    this.$api.fetch(this.api)
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>
<style scoped>
.list {
  font-size: 120%;
}
.list > ol, .list > ul {
  margin-bottom: 30px;
}
.list li {
  margin-bottom: 8px;
}
pre {
  font-family: inherit;
  white-space: pre-wrap;
  font-size: 120%;
}
</style>